import React from 'react';

import { QIcon, QStack, QText } from '@qualio/ui-components';
import ReactTooltip from 'react-tooltip';
import { Audit } from 'types';
import { getNextAuditDue, NextAuditDueInfo } from 'utils/audit';
import { ConfigItem } from 'v2types';

type NextAuditDueProps =
  | {
      audits: Audit[] | undefined;
      policyLinks?: any;
      supplierType: ConfigItem;
      riskLevel?: ConfigItem;
      auditTypes?: ConfigItem[];
      supplier?: string;
      isDetails?: boolean;
      isForPdfExport?: boolean;
    }
  | {
      nextDueInfo: NextAuditDueInfo | null;
      supplier?: string;
      isForPdfExport?: boolean;
    };

const NextAuditDue: React.FC<NextAuditDueProps> = (props) => {
  const nextDueInfo =
    'nextDueInfo' in props
      ? props.nextDueInfo
      : getNextAuditDue(
          props.audits,
          props.supplierType,
          props.policyLinks,
          props.riskLevel,
          props.auditTypes,
          props.supplier,
          props.isDetails,
        );

  if (nextDueInfo === null) return <>--</>;

  const { dueInRelative, dueType, auditType } = nextDueInfo;

  const getTextColor = (type: 'default' | 'error' | 'warning') => {
    switch (type) {
      case 'error':
        return 'red.500';
      case 'warning':
        return 'orange.500';
      default:
        return '';
    }
  };

  const styledDueText = (
    <QStack direction="row" spacing={2} alignItems="center" color={getTextColor(dueType)}>
      {dueType === 'error' && <QIcon iconName="AlertTriangle" />}
      {dueType === 'warning' && <QIcon iconName="Info" />}
      <QText data-tip="" data-for={props.supplier}>
        {dueInRelative}
      </QText>
      {!props.isForPdfExport && <ReactTooltip id={props.supplier}>Audit type: {auditType || '--'}</ReactTooltip>}
    </QStack>
  );

  return <>{styledDueText}</>;
};
export default NextAuditDue;
