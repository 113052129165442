import React from 'react';

import { QText } from '@qualio/ui-components';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';
import { Audit } from 'types';
import { getLastAuditDueMillis } from 'utils';

type LastAuditV2Props = {
  audits: Audit[] | undefined;
};

const LastAudit: React.FC<LastAuditV2Props> = ({ audits }) => {
  const latestAuditDate = getLastAuditDueMillis(audits);
  if (!latestAuditDate) return <>--</>;
  const auditFilter = audits?.filter(
    (audit) =>
      (audit.endDate || audit.modified) ===
      DateTime.fromMillis(latestAuditDate).toUTC().toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
  );

  const audit = auditFilter?.[0];

  return (
    <>
      <QText data-tip="" data-for={audit?.audit}>
        {DateTime.fromMillis(latestAuditDate).toFormat('MMM dd, yyyy')}
      </QText>
      {audit && (
        <ReactTooltip id={`${audit.audit}`}>
          Name: {audit.name} <br />
          Type: {audit.auditType?.title || '--'}
        </ReactTooltip>
      )}
    </>
  );
};
export default LastAudit;
